// 综合监控
import {getRequest, postRequest, monitorGateway, urlTitle, gateway} from "@/utils/request";
import qs from "qs";

window.qs = qs;
// 荷详情
export const source = {
    powergenEquipment: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenEquipment/info`, params)
    },

    powergenEnvironment: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenEnvironment/info`, params)
    },

    powergenGridConnection: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenGridConnection/info`, params)
    },

    powergenPhotoControllerltaic: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenPhotoControllerltaic/info`, params)
    },

    powergenPowerConsumptionStats: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenPowerConsumptionStats/info`, params)
    },

    powergenPowerStationBasic: (params) => {
        return getRequest(`${urlTitle}/powergen/detail/powergenPowerStationBasic/info`, params)
    },


};