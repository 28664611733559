<template>
  <!--  <v-scale-screen width="1920" height="1080" class="abcc" :fullScreen="true">-->
  <!--    -->
  <!--  </v-scale-screen>-->
  <div id="source">
    <myHeader></myHeader>
    <div class="titleBox">
      <div class="titleLeft">
        <div class="back_nav" @click="goBack()"></div>
      </div>
      <div class="titleCenter">
        <div style="margin-right: 10px;">{{ name }}</div>
        <div class="type" v-if="status == '1'">{{ '计划' }}</div>
        <div class="type" v-if="status == '2'">{{ '在建' }}</div>
        <div class="type" v-if="status == '3'">{{ '投运' }}</div>
      </div>
      <div class="titleRight"></div>
    </div>
    <div class="bigBox">
      <div class="topBox">
        <div class="topBoxLeft">
          <div class="topBoxLeftOne">
            <img src="../../assets/detail/img1.png" style="width: 100%;height: 100%;">
          </div>
          <div class="topBoxLeftTwo">
            <div class="smallItemBox">
              <img src="../../assets/detail/simg1.png" style="height: 24px;">
              <div class="itemLabel">业主:</div>
              <div class="itemValue">{{ userName }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg2.png" style="height: 24px;">
              <div class="itemLabel">运维负责人:</div>
              <div class="itemValue">{{ operationAndMaintenanceName }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg3.png" style="height: 24px;">
              <div class="itemLabel">电话:</div>
              <div class="itemValue">{{ phone }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg4.png" style="height: 24px;">
              <div class="itemLabel">地址:</div>
              <div class="itemValue">{{ address }}</div>
            </div>
          </div>
          <div class="topBoxLeftThree">
            <div class="smallItemBox">
              <img src="../../assets/detail/simg5.png" style="height: 24px;">
              <div class="itemLabel">电站类型:</div>
              <div class="itemValue">{{ stationType }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg6.png" style="height: 24px;">
              <div class="itemLabel">电站容量:</div>
              <div class="itemValue">{{ stationCapacity }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg7.png" style="height: 24px;">
              <div class="itemLabel">下次计划维护时间:</div>
              <div class="itemValue">{{ nextPlannedMaintenanceTime }}</div>
            </div>
            <div class="smallItemBox">
              <img src="../../assets/detail/simg8.png" style="height: 24px;">
              <div class="itemLabel">运维项目公司:</div>
              <div class="itemValue">{{ operationAndMaintenanceProjectCompany }}</div>
            </div>
          </div>
          <div class="topBoxLeftFour">
            <img src="../../assets/detail/circle.png" style="position: absolute;top: 30px;">
            <div class="circleBox">
              <div>{{ maintenanceDays }}</div>
              <div>{{ '天' }}</div>
            </div>
            <div>维护天数</div>
          </div>
        </div>
        <div class="topBoxRight">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '设备列表' }}</div>
          </div>
          <div class="tbr_b_box">
            <div class="tbr_box">
              <img src="../../assets/detail/simg10.png">
              <div class="tbr_r_box">
                <div>{{ '光伏逆变器' }}</div>
                <div>{{ photovoltaicInverter + '台' }}</div>
              </div>
            </div>
            <div class="tbr_box">
              <img src="../../assets/detail/simg9.png">
              <div class="tbr_r_box">
                <div>{{ '控制器' }}</div>
                <div>{{ controller + '台' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="centerBox">
        <div class="centerBoxLeft">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '用电量情况统计' }}</div>
          </div>
          <div class="chartBox">
            <div id="chart1" style="width: 94%;height: 90%;"></div>
            <div class="unitBox">
              <div class="unit">
                <div style="font-size: 20px;color: #ffc74b;">--kW</div>
                <div>光伏功率</div>
              </div>
              <div class="unit">
                <div style="font-size: 20px;color: #0096ff;">--kW</div>
                <div>电网功率</div>
              </div>
              <div class="unit">
                <div style="font-size: 20px;color: #2ee1b2;">--kW</div>
                <div>负荷功率</div>
              </div>
            </div>
          </div>
        </div>
        <div class="centerBoxRight">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '环境信息' }}</div>
          </div>
          <div class="cbr_r_box">
            <div class="s_l_box">
              <img src="../../assets/detail/simg11.png" style="margin-right: 20px;">
              <div class="s_l_box_r">
                <div style="color: #3095ff;">{{ atmosphericPressure }}</div>
                <div>{{ '大气压(hPa)' }}</div>
              </div>
            </div>
            <div class="ss_l_box">
              <div class="s_l_box">
                <img src="../../assets/detail/simg12.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ environmentTemperature }}</div>
                  <div>{{ '环境温度(°C)' }}</div>
                </div>
              </div>
              <div class="s_l_box">
                <img src="../../assets/detail/simg13.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ environmentHumidity + '%' }}</div>
                  <div>{{ '环境湿度(RH)' }}</div>
                </div>
              </div>
            </div>
            <div class="ss_l_box">
              <div class="s_l_box">
                <img src="../../assets/detail/simg14.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ componentTemperature }}</div>
                  <div>{{ '组件温度(°C)' }}</div>
                </div>
              </div>
              <div class="s_l_box">
                <img src="../../assets/detail/simg15.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ instantaneousRadiation }}</div>
                  <div>{{ '瞬时辐射(W/m³)' }}</div>
                </div>
              </div>
            </div>
            <div class="ss_l_box">
              <div class="s_l_box">
                <img src="../../assets/detail/simg16.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ sunlightRadiationAmount }}</div>
                  <div>{{ '日辐射量(Wh/m³)' }}</div>
                </div>
              </div>
              <div class="s_l_box">
                <img src="../../assets/detail/simg17.png" style="margin-right: 20px;">
                <div class="s_l_box_r">
                  <div style="color: #3095ff;">{{ totalRadiationAmount }}</div>
                  <div>{{ '总辐射量(Wh/m³)' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="bottomBoxLeft">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '光伏系统' }}</div>
          </div>
          <div id="chart2" style="width: 94%;height: 40%;"></div>
          <div class="b_b_box">
            <div class="b_b_box_l">
              <div class="ss_bg_box">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '日发电量:' }}</div>
                  <div class="ss_bg_value">{{ gDailyPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '累计发电量:' }}</div>
                  <div class="ss_bg_value">{{ gCumulativePowerGeneration + 'MWh' }}</div>
                </div>
              </div>
              <div class="ss_bg_box" style="margin: 3% 0">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '月发电量:' }}</div>
                  <div class="ss_bg_value">{{ gMonthlyPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '计划月发电量:' }}</div>
                  <div class="ss_bg_value">{{ gPlannedMonthlyPowerGeneration + 'MWh' }}</div>
                </div>
              </div>
              <div class="ss_bg_box">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '年发电量:' }}</div>
                  <div class="ss_bg_value">{{ gAnnualPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '计划年发电量:' }}</div>
                  <div class="ss_bg_value">{{ gPlannedAnnualPowerGeneration + 'MWh' }}</div>
                </div>
              </div>
            </div>
            <div class="b_b_box_r">
              <div id="chart3" style="width: 100%; height: 100%"></div>
            </div>
          </div>
        </div>
        <div class="bottomBoxRight">
          <div class="sTitleBox">
            <img src="../../assets/detail/timg1.png" style="margin-right: 10px">
            <div>{{ '并网系统' }}</div>
          </div>
          <div class="b_r_chart_box">
            <div id="chart4" style="width: 90%;height: 100%;"></div>
            <div class="unitBox">
              <div class="unit" style="margin-bottom: 20%;">
                <div style="font-size: 16px;color: #ffc74b;">--MWh</div>
                <div>累计上网电量</div>
              </div>
              <div class="unit">
                <div style="font-size: 16px;color: #0096ff;">--MWh</div>
                <div>累计下网电量</div>
              </div>
            </div>
          </div>
          <div class="b_b_box">
            <div class="b_b_box_l">
              <div class="ss_bg_box">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '日发电量:' }}</div>
                  <div class="ss_bg_value">{{ dailyPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '累计发电量:' }}</div>
                  <div class="ss_bg_value">{{ cumulativePowerGeneration + 'MWh' }}</div>
                </div>
              </div>
              <div class="ss_bg_box" style="margin: 3% 0">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '月发电量:' }}</div>
                  <div class="ss_bg_value">{{ monthlyPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '计划月发电量:' }}</div>
                  <div class="ss_bg_value">{{ plannedMonthlyPowerGeneration + 'MWh' }}</div>
                </div>
              </div>
              <div class="ss_bg_box">
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '年发电量:' }}</div>
                  <div class="ss_bg_value">{{ annualPowerGeneration + 'MWh' }}</div>
                </div>
                <div class="ss_bg">
                  <div class="ss_bg_label">{{ '计划年发电量:' }}</div>
                  <div class="ss_bg_value">{{ plannedAnnualPowerGeneration + 'MWh' }}</div>
                </div>
              </div>
            </div>
            <div class="b_b_box_r">
              <div id="chart5" style="width: 100%; height: 100%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter></HomeFooter>
  </div>
</template>
<script>
import myHeader from "@/components/homeHeader.vue";
import HomeFooter from "@/components/footer.vue";
import {source} from "@/api/source"
import * as echarts from "echarts";

export default {
  components: {myHeader, HomeFooter},
  name: "source",
  data() {
    return {
      stationId: '',
      //控制器
      controller: '',
      //光伏逆变器
      photovoltaicInverter: '',
      //大气压
      atmosphericPressure: '',
      //环境温度
      environmentTemperature: '',
      //环境湿度
      environmentHumidity: '',
      //组件温度
      componentTemperature: '',
      //瞬时辐射
      instantaneousRadiation: '',
      //日照辐射量
      sunlightRadiationAmount: '',
      //总辐射量
      totalRadiationAmount: '',
      //日发电量
      dailyPowerGeneration: '',
      //累计发电量
      cumulativePowerGeneration: '',
      //月发电量
      monthlyPowerGeneration: '',
      //计划月发电量
      plannedMonthlyPowerGeneration: '',
      //年发电量
      annualPowerGeneration: '',
      //计划年发电量
      plannedAnnualPowerGeneration: '',
      //光伏日发电量
      gDailyPowerGeneration: '',
      //光伏累计发电量
      gCumulativePowerGeneration: '',
      //光伏月发电量
      gMonthlyPowerGeneration: '',
      //光伏计划月发电量
      gPlannedMonthlyPowerGeneration: '',
      //光伏年发电量
      gAnnualPowerGeneration: '',
      //光伏计划年发电量
      gPlannedAnnualPowerGeneration: '',
      name: '',
      userName: '',
      operationAndMaintenanceName: '',
      phone: '',
      address: '',
      stationType: '',
      stationCapacity: '',
      nextPlannedMaintenanceTime: '',
      operationAndMaintenanceProjectCompany: '',
      maintenanceDays: '',
      status: ''
    }
  },
  created() {
    this.stationId = sessionStorage.getItem('stationId')
    this.getPowergenEquipment()
    this.getPowergenEnvironment()
    this.getPowergenGridConnection()
    this.getPowergenPhotoControllerltaic()
    this.getPowergenPowerConsumptionStats()
    this.getPowergenPowerStationBasic()
  },
  mounted() {
  },
  methods: {
    goBack(){
      this.$router.go(-1);
    },
    getPowergenEquipment() {
      const param = {
        id: this.stationId
      }
      source.powergenEquipment(param).then(res => {
        if (res.code == '200') {
          this.controller = res.data.controller
          this.photovoltaicInverter = res.data.photovoltaicInverter
        }
      })
    },
    getPowergenEnvironment() {
      const param = {
        id: this.stationId
      }
      source.powergenEnvironment(param).then(res => {
        if (res.code == '200') {
          this.atmosphericPressure = res.data.atmosphericPressure
          this.environmentTemperature = res.data.environmentTemperature
          this.environmentHumidity = res.data.environmentHumidity
          this.componentTemperature = res.data.componentTemperature
          this.instantaneousRadiation = res.data.instantaneousRadiation
          this.sunlightRadiationAmount = res.data.sunlightRadiationAmount
          this.totalRadiationAmount = res.data.totalRadiationAmount
        }
      })
    },
    getPowergenGridConnection() {
      const param = {
        id: this.stationId
      }
      source.powergenGridConnection(param).then(res => {
        if (res.code == '200') {
          this.dailyPowerGeneration = res.data.dailyPowerGeneration
          this.cumulativePowerGeneration = res.data.cumulativePowerGeneration
          this.monthlyPowerGeneration = res.data.monthlyPowerGeneration
          this.plannedMonthlyPowerGeneration = res.data.plannedMonthlyPowerGeneration
          this.annualPowerGeneration = res.data.annualPowerGeneration
          this.plannedAnnualPowerGeneration = res.data.plannedAnnualPowerGeneration
          this.getChart4(res.data.inputPowerList, res.data.feedbackPowerList,res.data.hourPeakValley)
          this.getChart5(res.data.instantaneousRadiationList)
        }
      })
    },
    getPowergenPhotoControllerltaic() {
      const param = {
        id: this.stationId
      }
      source.powergenPhotoControllerltaic(param).then(res => {
        if (res.code == '200') {
          this.gDailyPowerGeneration = res.data.dailyPowerGeneration
          this.gCumulativePowerGeneration = res.data.cumulativePowerGeneration
          this.gMonthlyPowerGeneration = res.data.monthlyPowerGeneration
          this.gPlannedMonthlyPowerGeneration = res.data.plannedMonthlyPowerGeneration
          this.gAnnualPowerGeneration = res.data.annualPowerGeneration
          this.gPlannedAnnualPowerGeneration = res.data.plannedAnnualPowerGeneration
          this.getChart2(res.data.instantaneousRadiationList, res.data.photovoltaicPowerList,res.data.hourPeakValley)
          this.getChart3(res.data.instantaneousRadiationHistogram)
        }
      })
    },
    getPowergenPowerConsumptionStats() {
      const param = {
        id: this.stationId
      }
      source.powergenPowerConsumptionStats(param).then(res => {
        if (res.code == '200') {
          console.log(res.data)
          this.getChart1(res.data.gridConnectedPowerList, res.data.loadPowerList, res.data.photovoltaicPowerList,res.data.hourPeakValley)
        }
      })
    },
    getPowergenPowerStationBasic() {
      const param = {
        id: this.stationId
      }
      source.powergenPowerStationBasic(param).then(res => {
        if (res.code == '200') {
          this.name = res.data.name
          this.userName = res.data.userName
          this.operationAndMaintenanceName = res.data.operationAndMaintenanceName
          this.phone = res.data.phone
          this.address = res.data.address
          this.stationType = res.data.stationType
          this.stationCapacity = res.data.stationCapacity
          this.nextPlannedMaintenanceTime = res.data.nextPlannedMaintenanceTime
          this.operationAndMaintenanceProjectCompany = res.data.operationAndMaintenanceProjectCompany
          this.maintenanceDays = res.data.maintenanceDays
          this.status = res.data.status
        }
      })
    },
    getChart1(gridConnectedPowerList, loadPowerList, photovoltaicPowerList,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart1'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '单位/kwh',
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '0',
          right: '4%',
          data: ['光伏功率', '并网功率', '负荷功率'],
          textStyle: {
            color: '#ffffff'
          },
          icon: 'circle'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {},
        xAxis: {
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          type: 'category',
          boundaryGap: true,
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          },

        },
        series: [
          {
            name: 'area',
            type: 'bar',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            }
          },
          {
            name: '光伏功率',
            type: 'line',
            data: photovoltaicPowerList,

          },
          {
            name: '并网功率',
            type: 'line',
            data: gridConnectedPowerList,
          },
          {
            name: '负荷功率',
            type: 'line',
            data: loadPowerList
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart2(instantaneousRadiationList, photovoltaicPowerList,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart2'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '单位: 功率(kW)',
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '0',
          right: '4%',
          data: ['瞬时辐射', '光伏功率'],
          textStyle: {
            color: '#ffffff'
          },
          icon: 'circle'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {},
        xAxis: {
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          type: 'category',
          boundaryGap: true,
          data:['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: 'area',
            type: 'bar',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            }
          },
          {
            name: '瞬时辐射',
            type: 'line',
            stack: 'Total',
            data: instantaneousRadiationList,
          },
          {
            name: '光伏功率',
            type: 'line',
            stack: 'Total',
            data: photovoltaicPowerList,
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart3(data) {
      var myChart = echarts.init(document.getElementById('chart3'), 'shine');
      let arr = []
      let arr1 = []
      data.forEach(item => {
        arr.push(item.date)
        arr1.push(item.histogram)
      })
      var option = {
        title: {
          text: '电量(kWh)',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        legend: {
          top: '0',
          right: '4%',
          show: true,
          data: ['瞬时辐射'],
          textStyle: {
            color: '#ffffff'
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          data: arr
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '瞬时辐射',
            data: arr1,
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              borderRadius: [5, 5, 0, 0] // 这里设置圆角的大小
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
    getChart4(inputPowerList, feedbackPowerList,hourPeakValley) {
      var myChart = echarts.init(document.getElementById('chart4'), 'shine');
      let colorList = ['rgba(58, 109, 212, 0.3)', 'rgba(58, 109, 212, 0.3)', 'rgba(61, 226, 188 , 0.3)', 'rgba(255, 199, 75 , 0.3)', 'rgba(255, 66, 66 , 0.3)'];
      let area = hourPeakValley
      var option = {
        title: {
          text: '单位: 功率(kW)',
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '0',
          right: '4%',
          data: ['上网电量', '下网电量'],
          textStyle: {
            color: '#ffffff'
          },
          icon: 'circle'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {},
        xAxis: {
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          type: 'category',
          boundaryGap: true,
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: 'area',
            type: 'bar',
            markArea: {
              data: [
                [
                  {
                    xAxis: '1',
                    itemStyle: {
                      color: colorList[area[0].periodType]
                    },
                  },
                  {
                    xAxis: '1'
                  }
                ],
                [
                  {
                    xAxis: '2',
                    itemStyle: {
                      color: colorList[area[1].periodType]
                    },
                  },
                  {
                    xAxis: '2'
                  }
                ], [
                  {
                    xAxis: '3',
                    itemStyle: {
                      color: colorList[area[2].periodType]
                    },
                  },
                  {
                    xAxis: '3'
                  }
                ], [
                  {
                    xAxis: '4',
                    itemStyle: {
                      color: colorList[area[3].periodType]
                    },
                  },
                  {
                    xAxis: '4'
                  }
                ], [
                  {
                    xAxis: '5',
                    itemStyle: {
                      color: colorList[area[4].periodType]
                    },
                  },
                  {
                    xAxis: '5'
                  }
                ], [
                  {
                    xAxis: '6',
                    itemStyle: {
                      color: colorList[area[5].periodType]
                    },
                  },
                  {
                    xAxis: '6'
                  }
                ], [
                  {
                    xAxis: '7',
                    itemStyle: {
                      color: colorList[area[6].periodType]
                    },
                  },
                  {
                    xAxis: '7'
                  }
                ], [
                  {
                    xAxis: '8',
                    itemStyle: {
                      color: colorList[area[7].periodType]
                    },
                  },
                  {
                    xAxis: '8'
                  }
                ], [
                  {
                    xAxis: '9',
                    itemStyle: {
                      color: colorList[area[8].periodType]
                    },
                  },
                  {
                    xAxis: '9'
                  }
                ], [
                  {
                    xAxis: '10',
                    itemStyle: {
                      color: colorList[area[9].periodType]
                    },
                  },
                  {
                    xAxis: '10'
                  }
                ], [
                  {
                    xAxis: '11',
                    itemStyle: {
                      color: colorList[area[10].periodType]
                    },
                  },
                  {
                    xAxis: '11'
                  }
                ], [
                  {
                    xAxis: '12',
                    itemStyle: {
                      color: colorList[area[11].periodType]
                    },
                  },
                  {
                    xAxis: '12'
                  }
                ],
                [
                  {
                    xAxis: '13',
                    itemStyle: {
                      color: colorList[area[12].periodType]
                    },
                  },
                  {
                    xAxis: '13'
                  }
                ],[
                  {
                    xAxis: '14',
                    itemStyle: {
                      color: colorList[area[13].periodType]
                    },
                  },
                  {
                    xAxis: '14'
                  }
                ],[
                  {
                    xAxis: '15',
                    itemStyle: {
                      color: colorList[area[14].periodType]
                    },
                  },
                  {
                    xAxis: '15'
                  }
                ],[
                  {
                    xAxis: '16',
                    itemStyle: {
                      color: colorList[area[15].periodType]
                    },
                  },
                  {
                    xAxis: '16'
                  }
                ],[
                  {
                    xAxis: '17',
                    itemStyle: {
                      color: colorList[area[16].periodType]
                    },
                  },
                  {
                    xAxis: '17'
                  }
                ],[
                  {
                    xAxis: '18',
                    itemStyle: {
                      color: colorList[area[17].periodType]
                    },
                  },
                  {
                    xAxis: '18'
                  }
                ],[
                  {
                    xAxis: '19',
                    itemStyle: {
                      color: colorList[area[18].periodType]
                    },
                  },
                  {
                    xAxis: '19'
                  }
                ],[
                  {
                    xAxis: '20',
                    itemStyle: {
                      color: colorList[area[19].periodType]
                    },
                  },
                  {
                    xAxis: '20'
                  }
                ],[
                  {
                    xAxis: '21',
                    itemStyle: {
                      color: colorList[area[20].periodType]
                    },
                  },
                  {
                    xAxis: '21'
                  }
                ],[
                  {
                    xAxis: '22',
                    itemStyle: {
                      color: colorList[area[21].periodType]
                    },
                  },
                  {
                    xAxis: '22'
                  }
                ],[
                  {
                    xAxis: '23',
                    itemStyle: {
                      color: colorList[area[22].periodType]
                    },
                  },
                  {
                    xAxis: '23'
                  }
                ],[
                  {
                    xAxis: '24',
                    itemStyle: {
                      color: colorList[area[23].periodType]
                    },
                  },
                  {
                    xAxis: '24'
                  }
                ],
              ]
            }
          },
          {
            name: '上网电量',
            type: 'line',
            stack: 'Total',
            data: feedbackPowerList,
          },
          {
            name: '下网电量',
            type: 'line',
            stack: 'Total',
            data: inputPowerList,
          }

        ]
      };
      myChart.setOption(option, true);
    },
    getChart5(data) {
      var myChart = echarts.init(document.getElementById('chart5'), 'shine');
      let arr = []
      let arr1 = []
      let arr2 = []
      data.forEach(item => {
        arr.push(item.date)
        arr1.push(item.gridEnergy)
        arr2.push(item.inputEnergy)
      })
      var option = {
        title: {
          text: '电量(kWh)',
          textStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        legend: {
          top: '0',
          right: '4%',
          show: true,
          data: ['上网电量', '下网电量'],
          textStyle: {
            color: '#ffffff'
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          data: arr
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'white'
          },
          axisLine: {
            lineStyle: {
              color: '#1b3c74'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#1b3c74',
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '上网电量',
            data: arr1,
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              borderRadius: [5, 5, 0, 0] // 这里设置圆角的大小
            }
          },
          {
            name: '下网电量',
            data: arr2,
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              borderRadius: [5, 5, 0, 0] // 这里设置圆角的大小
            }
          }
        ]
      };
      myChart.setOption(option, true);
    },
  }
}
</script>
<style scoped>
#footer {
  height: auto;
}

.back_nav {
  width: 76px;
  height: 40px;
  background: url('../../assets/images/monitoring/back_nav.png') no-repeat;
  background-size: 100% 90%;
  cursor: pointer;
}

.abcc {
  background-color: #071024 !important;
}

#source {
  height: 100vh;
  background-color: #071024;
  font-size: 0.24rem;
  box-sizing: border-box;
}

.titleBox {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleLeft {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.titleCenter {
  height: 80px;
  flex: 1;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
}

.titleCenter .type {
  background-color: #06af85;
  width: 4%;
  height: 0.28rem;
  line-height: 0.28rem;
  text-align: center;
  font-size: 0.16rem;
  border-radius: 0.05rem;
}

.bigBox {
  //height: 100%;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-color: #071024;
}

.topBox {
  height: 260px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.topBoxLeft {
  padding: 20px 20px;
  width: 70%;
  height: 100%;
  background: url(../../assets/images/top_bg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.topBoxLeftOne {
  flex: 1.2;
  height: 100%;
  margin-right: 20px;
}

.topBoxLeftTwo {
  flex: 1.9;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.topBoxLeftThree {
  flex: 1.9;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.topBoxLeftFour {
  flex: 1;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
}

.circleBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.topBoxRight {
  box-sizing: border-box;
  padding: 20px;
  background: url(../../assets/detail/bgc2.png) no-repeat;
  background-size: 100% 100%;
  width: 29%;
  height: 100%;
  margin-left: 1%;
}

.tbr_b_box {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.tbr_box {
  flex: 0.4;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.tbr_r_box {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
}

.centerBox {
  display: flex;
  width: 100%;
  height: 460px;
  margin-top: 20px;
}

.centerBoxLeft {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  width: 70%;
  background: url(../../assets/detail/bgc3.png) no-repeat;
  background-size: 100% 100%;
}

.centerBoxLeft .chartBox {
  height: 90%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.chartBox .unitBox {
  height: 60%;
  font-size: 16px;
  width: 6%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.centerBoxRight {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  margin-left: 1%;
  width: 29%;
  background: url(../../assets/detail/bgc4.png) no-repeat;
  background-size: 100% 100%;
}

.ss_l_box {
  margin-top: 2%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.ss_l_box .s_l_box {
  box-sizing: border-box;
  width: 49%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.bottomBox {
  width: 100%;
  //flex: 3;
  margin: 20px 0 20px 0;
  height: 600px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.bottomBoxLeft {
  background: url(../../assets/detail/bgc5.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  width: 49.5%;
  height: 100%;
  margin-right: 1%;
  padding: 0 20px 20px 20px;
}

.bottomBoxRight {
  background: url(../../assets/detail/bgc5.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  width: 49.5%;
  height: 100%;
  padding: 0 20px 20px 20px;
}

.b_r_chart_box {
  width: 100%;
  height: 40%;
  display: flex;
  flex-flow: wrap;
}

.b_r_chart_box .unitBox {
  width: 10%;
  font-size: 14px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}


.smallItemBox {
  height: 80px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
}

.itemLabel {
  height: 24px;
  font-size: 16px;
  margin: 0 10px 0 4px;
}

.itemValue {
  height: 24px;
  font-size: 16px;
}

.sTitleBox {
  height: 50px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}

.s_l_box {
  height: 80px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: url(../../assets/detail/sbgc1.png) no-repeat;
  background-size: 100% 100%;
}

.s_l_box_r {
  font-size: 14px;
}

.b_b_box {
  margin-top: 1%;
  height: 50%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.b_b_box .b_b_box_l {
  width: 48%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.b_b_box .b_b_box_r {
  width: 48%;
  height: 100%;
}

.ss_bg_box {
  height: 25%;
  font-size: 14px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
}

.ss_bg_box .ss_bg {
  width: 48%;
  height: 100%;
  background: url(../../assets/detail/sbgc2.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 3%;
}

.ss_bg_box .ss_bg .ss_bg_label {
  margin-right: 2%;
  color: #bdccdf;
}

.ss_bg_box .ss_bg .ss_bg_value {
  color: #0096ff;
}
</style>
